<template>
  <div v-if="bot" id="chatbot" class="fixed xl:bottom-20 bottom-0 xl:right-0 left-0 xl:left-auto z-[999]">
    <div
      class="chatbot-icon fixed lg:relative bottom-3 max-xl:bottom-20 right-3 lg:bottom-auto lg:right-auto w-14 h-14 rounded-full bg-bluePrimary text-white flex justify-center items-center text-3xl cursor-pointer"
      @click.prevent="toggleChatbot()"
    >
      <img src="/images/user/icons/chatbot.svg" alt="chatbot" />
    </div>
    <div
      v-show="boxChatShow"
      class="chatbot-box absolute bottom-0 flex flex-col xl:-left-3 left-0 xl:-translate-x-full xl:h-[500px] h-screen xl:w-96 w-screen bg-white rounded-md z-[999] before:border-l-transparent before:border-t-transparent before:w-3 before:h-3 xl:before:content-[''] before:absolute before:bottom-5 before:right-0 before:translate-x-1/2 before:-z-10 before:rotate-45 before:bg-white border border-[#8E8E8E] shadow shadow-[#8E8E8E]"
      :style="{
        backgroundColor: bot?.colors?.mainColor,
      }"
    >
      <div
        class="box-header flex justify-start items-center bg-gray-400 rounded-tr-md rounded-tl-md text-white font-semibold"
        :style="{
          backgroundColor: bot?.colors?.headerColor,
          color: bot?.colors?.textHeaderFooterColor,
        }"
      >
        <div
          v-if="isSignUp"
          class="action-close w-8 text-center text-xl cursor-pointer flex items-center justify-center"
          @click.prevent="refreshChatbot()"
        >
          <i class="ri-refresh-line" />
        </div>
        <div class="user-name w-full py-4 text-center">
          {{ bot.display_name ?? 'Bot' }}
        </div>
        <div
          class="action-close w-8 text-center text-xl cursor-pointer flex items-center justify-center"
          @click.prevent="toggleChatbot()"
        >
          <i class="ri-close-line" />
        </div>
      </div>
      <div
        v-if="isSignUp"
        ref="msgContainer"
        class="box-body flex flex-col items-start h-full w-full overflow-y-auto"
        @scroll="handleScrollMessage()"
      >
        <template v-for="(msg, index) in webChat.messageList" :key="index">
          <div v-if="!checkDateTimeNewAndOld(index)" class="message-date text-center mb-2 w-full mt-3 first:mt-0">
            {{ formatDateTimeToDate(msg.created_at) }}
          </div>
          <div class="flex flex-row w-full">
            <div v-if="msg.author === 'bot'" class="mx-2">
              <img src="/images/vntf-avatar.png" class="w-10 rounded-full">
            </div>
            <div
              class="message-box w-full last:mb-2 flex flex-col-reverse"
              :class="{
                'items-start': msg.author === 'bot',
                'items-end': msg.author === 'me',
                'mt-4': checkType(index),
              }"
            >
              <span
                class="user-name flex justify-start items-center w-full"
                :class="{
                  'flex-row-reverse': msg.author === 'me',
                }"
                :style="{
                  color: bot?.colors?.iconColor,
                }"
              >
                <!-- <i v-if="msg.author === 'bot'" class="ri-robot-fill text-md" /> -->
                <!-- <i v-if="msg.author === 'me'" class="ri-user-3-fill text-md" /> -->
                <span
                  class="text-[12px]"
                  :class="{
                    'mr-1': msg.author === 'me',
                  }"
                >
                  {{ formatDateTimeToTime(msg.created_at) }}
                </span>
              </span>
              <div
                class="message-item py-1 px-2 flex flex-col break-all text-white cursor-pointer max-w-[80%]"
                :class="{
                  'bg-red-400 rounded-l-xl': msg.author === 'me',
                  'bg-bluePrimary rounded-r-xl': msg.author === 'bot',
                  'max-w-[75%] w-full': msg?.data?.buttons?.length,
                }"
                :title="!checkShow(index) ? msg.created_at : ''"
                :style="{
                  backgroundColor: msg.author == 'bot' ? bot?.colors?.messageBotColor : bot?.colors?.messageColor,
                }"
              >
                <span
                  class="message-text whitespace-pre-line break-all"
                  :class="{ 'mb-1 last:mb-0': msg?.data?.buttons || msg?.data?.image }"
                  :style="{
                    color: msg.author == 'bot' ? bot?.colors?.textBotColor : bot?.colors?.textColor,
                  }"
                >
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <span style="word-break: break-word;" v-html="sanitizeHtml(convertURL(msg?.data?.text))" />
                </span>
                <span v-if="msg?.data?.buttons?.length" class="message-buttons flex flex-col gap-1 w-full">
                  <span
                    v-for="(button, i) in msg?.data?.buttons"
                    :key="i"
                    class="message-button last:mb-3 w-full px-5 py-2 rounded-full bg-white cursor-pointer text-black text-center font-semibold"
                    style="word-break: break-word;"
                    :style="{
                      color: bot?.colors?.textButtonColor,
                      backgroundColor: bot?.colors?.buttonBotColor,
                    }"
                    @click.prevent="handleClickButtonAction(button)"
                  >
                    {{ button.title }}
                  </span>
                </span>
                <span v-if="msg?.data?.url" class="message-image">
                  <el-image :src="msg?.data?.url" fit="cover" />
                </span>
              </div>
            </div>
          </div>
        </template>
        <div v-if="webChat.showTypingIndicator" class="dot-elastic w-full h-3 flex justify-center items-center mt-auto">
          <div
            class="relative w-2 h-2 rounded bg-gray-500 text-gray-500 animate-dot-elastic after:content-['*'] before:content-['*'] before:inline-block after:inline-block before:absolute after:absolute after:top-0 before:top-0 before:-left-4 before:w-2 before:h-2 before:rounded before:bg-gray-500 before:text-gray-500 before:animate-dot-elastic-before after:left-4 after:w-2 after:h-2 after:rounded after:bg-gray-500 after:text-gray-500 after:animate-dot-elastic-after"
          />
        </div>
      </div>
      <div v-if="isSignUp" class="box-footer rounded-bl-sm rounded-br-sm flex justify-start">
        <div
          class="box-input w-full bg-gray-300 rounded-bl-sm"
          :style="{
            backgroundColor: bot?.colors?.headerColor,
            color: bot?.colors?.textHeaderFooterColor,
          }"
        >
          <textarea
            v-model="message"
            class="chat-input w-full border-0 bg-transparent resize-none h-full"
            rows="1"
            @keydown.enter.exact.prevent="sendNewMessage"
          />
        </div>
        <div
          class="chat-send-btn w-11 flex justify-center items-center cursor-pointer text-white bg-gray-300 hover:bg-gray-500 rounded-br-sm"
          :style="{
            color: bot?.colors?.buttonSendColor,
            backgroundColor: bot?.colors?.buttonColor,
          }"
          @click.prevent="sendNewMessage"
        >
          <i class="ri-send-plane-fill" />
        </div>
      </div>
      <div v-if="false" class="form-chatbot p-3">
        <el-form ref="chatbotForm" :model="formData" label-position="top" :rules="rules">
          <el-form-item :label="$t('Họ và tên')" prop="full_name">
            <el-input v-model="formData.full_name" />
          </el-form-item>
          <el-form-item :label="$t('Email')" prop="email">
            <el-input v-model="formData.email" />
          </el-form-item>
          <el-form-item :label="$t('Số điện thoại')" prop="phone_number">
            <el-input v-model="formData.phone_number" />
          </el-form-item>
          <el-form-item class="mt-8">
            <el-button @click="formData = {}">Reset</el-button>
            <el-button type="primary" @click="signUp()">Submit</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/plugins/axios'
import dayjs from 'dayjs'
import sanitizeHtml from '@/plugins/sanitizeHtml'

export default {
  data() {
    return {
      formData: {
        full_name: null,
        email: null,
        phone_number: null,
      },
      rules: {
        full_name: [
          {
            required: true,
            whitespace: true,
            message: 'Trường này không được bỏ trống.',
            trigger: ['blur', 'change'],
          },
          { max: 255, message: 'Trường này cho phép nhập tối đa 255 ký tự.', trigger: ['change', 'blur'] },
        ],
        phone_number: {
          required: true,
          whitespace: true,
          pattern: '([0-9])$',
          message: 'Trường này không được bỏ trống và chỉ gồm các số 0 - 9',
          trigger: ['blur', 'change'],
        },
        email: {
          required: true,
          type: 'email',
          whitespace: true,
          message: 'Trường này không được bỏ trống và phải là một email.',
          trigger: ['blur', 'change'],
        },
      },
      message: null,
      boxChatShow: false,
      isSignUp: false,
      webChat: {
        form: null,
        participants: [
          {
            id: 'bot',
            name: '',
          },
        ],
        titleImageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
        messageList: [],
        newMessagesCount: 0,
        showTypingIndicator: false,
        colors: {
          header: {
            bg: '#4e8cff',
            text: '#ffffff',
          },
          launcher: {
            bg: '#4e8cff',
          },
          messageList: {
            bg: '#ffffff',
          },
          sentMessage: {
            bg: '#4e8cff',
            text: '#ffffff',
          },
          receivedMessage: {
            bg: '#eaeaea',
            text: '#222222',
          },
          userInput: {
            bg: '#f4f7f9',
            text: '#565867',
          },
        },
        alwaysScrollToBottom: false,
        messageStyling: true,
        isOpenUserInfomation: false,
        isOpenUserColor: false,
        isShowReFreshMessage: true,
        isPending: false,
      },
      guest_id: localStorage.getItem('guest-id') ?? null,
      guest_session: localStorage.getItem('guest-session') ?? null,
      params: {
        limit: 20,
      },
      isLoadMore: true,
      isStartUp: false,
      blockType: null,
    }
  },
  computed: {
    firstMessageId: function () {
      return this.webChat.messageList[0]?.id
    },
    bot() {
      return this.$page?.props?.bot ?? false
    },
  },
  methods: {
    async toggleChatbot() {
      this.boxChatShow = !this.boxChatShow
      if (this.boxChatShow) {
        const guest_session = this.guest_session
        if (!guest_session) {
          const params = {
            full_name: this.$page?.props?.auth?.user?.name ?? 'guest',
            email: this.$page?.props?.auth?.user?.email ?? 'guest@yopmail.com',
            phone_number: this.$page?.props?.auth?.user?.user_phone_number.replace(' ', '') ?? '000000000',
          }
          await this.handleSignUp(params)
        }
        if (guest_session) {
          this.isSignUp = true
          this.isLoadMore = false
          await this.getMessageHistories()

          if (!this.webChat.messageList.length) {
            await this.getStartBlock()
          }
          this.gotoBottomChat()
        }
      }
    },
    checkShow(index) {
      if (!index) return true
      return (
        this.webChat?.messageList?.[index]?.author != this.webChat?.messageList?.[index - 1]?.author ||
        this.checkTime(index)
      )
    },
    checkType(index) {
      if (index === 0) return false
      return (
        this?.webChat.messageList?.[index]?.author != this.webChat?.messageList?.[index - 1]?.author ||
        this.checkTime(index)
      )
    },
    checkTime(index) {
      const oldTime = this?.webChat.messageList?.[index - 1]?.created_at
      const newTime = this?.webChat.messageList?.[index]?.created_at
      return dayjs(newTime, 'DD-MM-YYYY HH:mm').diff(dayjs(oldTime, 'DD-MM-YYYY HH:mm'), 'minute', true) >= 3
    },
    formatDateTimeToDate(dateTime) {
      return dayjs(dateTime, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY')
    },
    formatDateTimeToTime(dateTime) {
      return dayjs(dateTime, 'DD-MM-YYYY HH:mm').format('HH:mm')
    },
    checkDateTimeNewAndOld(index) {
      if (index === 0) return false
      const oldDate = dayjs(this?.webChat.messageList?.[index - 1]?.created_at, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY')
      const newDate = dayjs(this?.webChat.messageList?.[index]?.created_at, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY')
      return newDate === oldDate
    },
    async signUp() {
      // const guest_session = this.guest_session
      // if (guest_session !== undefined && guest_session !== null) return
      // this.$refs.chatbotForm.validate(async (valid) => {
      //   if (!valid) return
      await this.handleSignUp(this.formData)
      // })
    },
    async handleSignUp(formData = {}) {
      const { data, status } = await axios.post('/sign-up', formData)

      if (status == 200) {
        this.isSignUp = true
        this.isLoadMore = false
        const { session, id } = data.data
        localStorage.setItem('guest-session', session)
        this.guest_session = session

        localStorage.setItem('guest-id', id)
        localStorage.setItem('flag', 0)
        this.guest_id = id
        this.getStartBlock()
      }
    },
    async getStartBlock() {
      this.webChat.showTypingIndicator = true
      setTimeout(() => {
        this.fetchStartMessage()
        this.webChat.showTypingIndicator = false
      }, 1000)
    },
    async fetchStartMessage() {
      const id = (Math.random() + 1).toString(36).substring(7)
      const guest_session = this.guest_session

      const { data, status } = await axios.get('/get-start-message', { params: { guest_session, key: this.bot.key } })
      if (status == 200) {
        data?.data.forEach((message) => {
          this.onMessageWasSent(null, {
            id: id,
            author: 'bot',
            data: message.content,
            type: 'text',
            created_at: message.created_at,
          })
        })
        this.gotoBottomChat()
      }
    },
    async getMessageHistories() {
      const guest_session = this.guest_session
      const params = {
        ...this.params,
        cursor_id: this.firstMessageId,
        guest_session,
        key: this.bot.key,
      }
      const { data, status } = await axios.get('/get-history', { params })

      const filteredData = data?.data.filter(function (item) {
        return item.message['type'] != 'form'
      })

      if (status == 405) {
        localStorage.removeItem('guest-id')
        localStorage.removeItem('guest-session')
      }
      const messages = []
      const message = filteredData
      for (const item of message) {
        if (this.guest_id == item.sender_id) {
          messages.push({
            id: item.id,
            author: 'me',
            type: item?.message?.type || 'text',
            created_at: item.created_at,
            data: {
              ...item.message,
              text: item.message.text,
            },
          })
        } else {
          messages.push({
            id: item.id,
            author: 'bot',
            type: item?.message?.type || 'text',
            created_at: item.created_at,
            data: {
              ...item.message,
              url: item.message.url,
              title: item.message?.text || item.message.title,
              content: item.message.buttons,
            },
          })
        }
      }
      this.webChat.messageList.unshift(...messages.reverse())
    },
    async getTypeOfNextBlock(arg) {
      const params = {
        next_block_id: arg.next_block_id,
      }
      const { status, data } = await axios.get('/get-type-of-next-block', { params })
      this.blockType = status === 200 ? data?.data?.type : this.blockType
    },
    async sendNewMessage() {
      const id = (Math.random() + 1).toString(36).substring(7)
      if (this.message) {
        let message = {
          text: this.message,
          type: 'text',
        }

        this.onMessageWasSent(null, {
          id: id,
          author: 'me',
          type: 'text',
          created_at: this.getCurrentDate(),
          data: message,
        })

        await this.handleNewMessage(message)
        this.gotoBottomChat()
        this.message = null
      }
    },
    async sendMessage(arg) {
      const id = (Math.random() + 1).toString(36).substring(7)
      const guest_session = this.guest_session
      const key = this.bot.key
      const params = {
        ...arg,
        id_message: id,
        message: { text: arg.title ? arg.title : arg.item.title },
        is_save: true,
        guest_session,
        key,
      }

      //   delete params.title
      const { data, status } = await axios.post('/handle-message', params)

      if (status == 200) {
        const item = data?.data
        const message = {
          id: item?.id,
          author: 'me',
          type: item?.message?.type || 'text',
          created_at: item.created_at,
          data: {
            ...item.message,
            text: item.message.text,
          },
        }
        this.webChat.messageList.push(message)
        this.getDataNextBlock(arg)
        this.gotoBottomChat()
      }
    },
    async handleClickButtonAction(params) {
      if (params.classify == 'keyword') {
        let id = (Math.random() + 1).toString(36).substring(7)
        this.onMessageWasSent(null, {
          id: id,
          author: 'me',
          data: {
            text: params.title ? params.title : params.item.title,
            type: 'text',
          },
          type: 'text',
          created_at: this.getCurrentDate(),
        })
        this.gotoBottomChat()
        this.handleSendTopic(params)
      } else {
        await this.getTypeOfNextBlock(params)
        switch (this.blockType) {
          case 'start_block':
            this.refreshChatbot()
            break
          default:
            this.sendMessage(params)
            this.gotoBottomChat()
        }
      }
    },
    async getDataNextBlock(arg) {
      if (!arg.next_block_id) {
        this.webChat.showTypingIndicator = false
        return
      }
      arg.guest_session = this.guest_session
      const { data, status } = await axios.get('/get-data-of-next-block', { params: { ...arg } })

      if (status === 200) {
        this.webChat.showTypingIndicator = true
        setTimeout(() => {
          if (data?.data.length) {
            data?.data.forEach((item) => {
              this.onMessageWasSent(null, {
                id: item.id,
                author: 'bot',
                data: item.content,
                type: 'text',
                created_at: item.created_at,
              })
            })
          } else {
            let id = (Math.random() + 1).toString(36).substring(7)
            this.onMessageWasSent(null, {
              id: id,
              author: 'bot',
              data: {
                text: 'Không tìm thấy kết quả phù hợp',
                title: 'Không tìm thấy kết quả phù hợp',
                type: 'text',
              },
              type: 'text',
              created_at: this.getCurrentDate(),
            })
          }
          this.webChat.showTypingIndicator = false
          this.gotoBottomChat()
        }, 2000)
      } else {
        this.webChat.showTypingIndicator = false
      }
    },
    onMessageWasSent(id, message) {
      this.webChat.messageList = [...this.webChat.messageList, { id, ...message }]
    },
    async handleNewMessage(message) {
      const guest_session = this.guest_session
      const params = {
        message: message,
        guest_session,
      }

      const { data, status } = await axios.post('/handle-new-message', params)
      if (status == 200) {
        this.webChat.showTypingIndicator = true
        setTimeout(() => {
          const item = data?.data
          this.onMessageWasSent(null, {
            id: item.id,
            author: 'bot',
            data: item.message,
            type: 'text',
            created_at: item.created_at,
          })
          this.webChat.showTypingIndicator = false
          this.gotoBottomChat()
        }, 2000)
      }
    },
    async handleSendTopic(params) {
      const guest_session = this.guest_session
      const formData = {
        guest_session,
        message: { text: params.title ? params.title : params.item.title, type: 'text' },
        type: 'text',
      }

      const { data, status } = await axios.post('/handle-send-topic', formData)
      if (status == 200) {
        this.webChat.showTypingIndicator = true
        setTimeout(() => {
          data?.data.forEach((item) => {
            this.onMessageWasSent(null, {
              id: item?.id,
              author: 'bot',
              data: item.content,
              type: 'text',
              created_at: item.created_at,
            })
          })
          this.webChat.showTypingIndicator = false
          this.gotoBottomChat()
        }, 2000)
      }
    },
    getCurrentDate() {
      let today = new Date()
      let dd = String(today.getDate()).padStart(2, '0')
      let mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
      let yyyy = today.getFullYear()
      let H = String(today.getHours())
      let i = String(today.getMinutes())

      return `${dd}-${mm}-${yyyy} ${H}:${i}`
    },
    gotoBottomChat() {
      this.$nextTick(function () {
        var container = this.$refs.msgContainer
        container.scrollTop = container.scrollHeight
      })
    },
    async refreshChatbot() {
      await this.getStartBlock()
      this.gotoBottomChat()
    },
    async handleLoadMoreMessage() {
      if (!this.isLoadMore || this.isStartUp || this.webChat.isPending) {
        return
      }
      const elMsgList = this.$refs.msgContainer
      const oldOffetScrollHeight = elMsgList.scrollHeight

      this.$eventBus.publish('show-loading-more-messages')
      this.webChat.isPending = true
      const cards = await this.getMessageHistories()
      this.webChat.isPending = false
      this.$eventBus.publish('hide-loading-more-messages')
      const newOffetScrollHight = elMsgList.scrollHeight - 40

      elMsgList.scrollTop = newOffetScrollHight - oldOffetScrollHeight

      if (cards?.length < this.params.limit) {
        this.isLoadMore = false
      }
    },
    handleScrollMessage() {
      let container = this.$refs.msgContainer
      let scrollTop = container.scrollTop
      if (scrollTop === 0) {
        this.isLoadMore = true
        this.handleLoadMoreMessage()
      }
    },
    convertURL(string) {
      const urls = string?.match(/((((ftp|https?):\/\/)|(w{3}\.))[\-\w@:%_\+.~#?,&\/\/=]+)/g)
      if (urls) {
        urls.forEach(function (url) {
          string = string.replace(url, '<a href="' + url + '">' + url + '</a>')
        })
      }
      return string
    },
    sanitizeHtml,
  },
}
</script>
<style>
.message-text a {
  text-decoration: underline;
}
</style>
