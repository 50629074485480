<template>
  <div v-if="visible" class="fixed xl:bottom-0 bottom-0 xl:right-0 xl:left-auto md:right-0 z-[888]">
    <div
      class="fixed lg:relative bottom-3 right-3 lg:bottom-auto lg:right-auto w-12 h-14 bg-bluePrimary text-white flex justify-center items-center text-3xl cursor-pointer"
      @click="scrollToTop"
    >
      <i class="ri-arrow-up-fill text-white text-3xl" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    }
  },
  mounted: function () {
    window.addEventListener('scroll', this.scrollListener)
  },
  beforeDestroy: function () {
    window.removeEventListener('scroll', this.scrollListener)
  },
  methods: {
    scrollToTop: function () {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId)
        }
        window.scroll(0, window.pageYOffset - 50)
      }, 2)
    },
    scrollListener: function (e) {
      this.visible = window.scrollY > 300
    },
  }
}
</script>