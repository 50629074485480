<template>
  <div
    class="primary-sidebar-toggler cursor-pointer lg:hidden fixed top-4 left-0 text-3xl z-[500] flex justify-center items-center rounded-r-xl transition-all duration-500"
    :class="{ 'bg-bluePrimary text-white p-2 hover:pl-4': !isSidebarToggled, 'text-bluePrimary': isSidebarToggled }"
    @click="toggleSidebar"
  >
    <svg
      class="transition-all duration-500 fill-white"
      :class="{ 'hidden opacity-0': isSidebarToggled, 'opacity-100': !isSidebarToggled }"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M3 4h18v2H3V4zm0 7h12v2H3v-2zm0 7h18v2H3v-2z" />
    </svg>
    <svg
      class="transition-all duration-500 ml-5"
      :class="{ 'hidden opacity-0': !isSidebarToggled, 'opacity-100': isSidebarToggled }"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
      />
    </svg>
  </div>
  <div
    class="absolute max-w-screen w-full h-screen bg-black bg-opacity-50 top-0 left-0 z-30"
    :class="{ hidden: !isSidebarToggled }"
    @click="toggleSidebar"
  />
  <div
    class="primary-sidebar transition-all duration-500 left-auto z-50 fixed"
    :class="{ 'max-lg:-left-full': !isSidebarToggled }"
  >
    <div class="sidebar-brand flex justify-center items-center mb-10" @click.prevent="redirectHome()">
      <img src="/images/user/logo/logo.png" alt="brand_logo" />
    </div>
    <div class="sidebar-menu px-3 mb-10">
      <el-menu
        :default-active="defaultActive"
        class="break-words"
        :class="defaultActive"
        active-text-color="#FFF"
        style="
          --el-menu-active-bg-color: #2078ce;
          --el-menu-base-level-padding: 10px;
          --el-menu-level-padding: 10px;
          --el-menu-icon-width: 24px;
          --el-menu-item-height: auto;
          --el-menu-sub-item-height: auto;
          --el-menu-item-font-size: 16px;
        "
      >
        <template v-for="(menu, i) in primaryMenus" :key="i + ''">
          <el-menu-item v-if="!menu?.subMenus?.length" :index="menu.route ?? i + ''" @click="onMenuClick(menu)">
            <a :href="route(menu.route)" class="whitespace-pre-wrap leading-normal py-4 text-[#303133] w-full h-full">
              <span>{{ $t(menu.label) }}</span>
            </a>
          </el-menu-item>
          <el-sub-menu v-else :index="menu.route ?? i + ''">
            <template #title>
              <span class="whitespace-pre-wrap leading-normal py-4">{{ $t(menu.label) }}</span>
            </template>
            <template v-for="(menuChild, ind) in menu.subMenus" :key="i + '-' + ind">
              <el-menu-item
                v-if="!menuChild?.subMenus?.length"
                :index="menuChild.route ?? i + '-' + ind"
                @click="onMenuClick(menuChild)"
              >
                <a
                  :href="route(menuChild.route)"
                  class="whitespace-pre-wrap leading-normal py-4 text-[#303133] w-full h-full"
                >
                  <span>{{ $t(menuChild.label) }}</span>
                </a>
              </el-menu-item>
              <el-sub-menu v-else :index="menuChild.route ?? i + '-' + ind">
                <template #title>
                  <span class="whitespace-pre-wrap leading-normal py-4">{{ $t(menuChild.label) }}</span>
                </template>
                <template v-for="(menuChildChild, index) in menuChild.subMenus" :key="i + '-' + ind + '-' + index">
                  <el-menu-item
                    v-if="!menuChildChild?.subMenus?.length"
                    :index="menuChildChild.route ?? i + '-' + ind + '-' + index"
                    @click="onMenuClick(menuChildChild)"
                  >
                    <a
                      :href="route(menuChildChild.route)"
                      class="whitespace-pre-wrap leading-normal py-4 text-[#303133] w-full h-full"
                    >
                      <span span>{{ $t(menuChildChild.label) }}</span>
                    </a>
                  </el-menu-item>
                </template>
              </el-sub-menu>
            </template>
          </el-sub-menu>
        </template>
      </el-menu>
    </div>
    <div class="sidebar-auth flex flex-col mt-10 px-6">
      <!-- <div class="sidebar-language-switcher flex justify-start items-center mb-4 text-base lg:hidden">
        <span class="w-6 h-6 text-bluePrimary">
          <i class="ri-earth-line" />
        </span>
        <select
          ref="currentLang"
          class="border-0 outline-0 py-0 focus:border-0 focus:outline-0 focus:shadow-none focus:border-transparent text-textBlackPrimary"
          @change="toggleLang"
        >
          <option value="vi" selected>VN</option>
          <option value="en">EN</option>
        </select>
      </div> -->
      <template v-if="!user?.id">
        <a href="/login" class="sidebar-sign-in flex justify-start items-center mb-4 text-base">
          <i class="ri-user-3-fill mr-2 text-bluePrimary" />
          <span class="text-textBlackPrimary">Đăng nhập</span>
        </a>
        <a
          href="/register"
          class="sidebar-sign-up bg-[#F49D2E] w-full rounded-full text-center text-base text-white py-2"
          >Đăng ký</a
        >
      </template>

      <template v-else>
        <div
          id="notification-button"
          class="flex justify-start items-center cursor-pointer text-textBlackPrimary rounded-md hover:bg-[#F0F0F0] w-full p-2"
          @click="toggleNotifications()"
        >
          <i
            class="ri-notification-3-line text-2xl notification-bell text-bluePrimary"
            :class="{
              'is-none': !notifications,
            }"
          />
          <span class="ml-1">{{ $t('Thông báo') }}</span>
        </div>
        <div
          class="flex justify-start items-center cursor-pointer mb-5 text-textBlackPrimary rounded-md hover:bg-[#F0F0F0] w-full p-2"
          :class="{ 'bg-[#F0F0F0]': activeMyprofile }"
          @click="showProfile"
        >
          <div class="w-6 h-6 overflow-hidden rounded-full">
            <el-avatar v-if="user.avatar" :size="24" :src="user.avatar" />
            <template v-else>
              <el-avatar :size="24">{{ user.name.trim().charAt(0) }}</el-avatar>
            </template>
          </div>
          <span class="ml-1">{{ user.name }}</span>
        </div>

        <div
          class="logout flex justify-start items-center text-center text-textGrayButton cursor-pointer -m-3 p-3 rounded-md hover:bg-[#F0F0F0]"
          @click.prevent="doLogout"
        >
          <i class="ri-logout-circle-line" />
          <span class="ml-1">{{ $t('Đăng xuất') }}</span>
        </div>
      </template>
    </div>
  </div>
  <NotificationPopup ref="notificationPopup" @update-unread="handleUpdateUnread" />
</template>
<script setup>
import { onBeforeMount, ref } from 'vue'
import { getActiveLanguage, loadLanguageAsync } from 'laravel-vue-i18n'
import { Inertia } from '@inertiajs/inertia'

let isSidebarToggled = ref(false)
let activeLanguage = localStorage.getItem('cLocale') ?? getActiveLanguage()
let defaultActive = ref('home')
let currentLang = ref(activeLanguage)
let activeMyprofile = ref(false)
let primaryMenus = ref([
  {
    label: 'Trang chủ',
    route: 'home',
    subMenus: [],
    pathActive: [''],
  },
  {
    label: 'Về Chúng Tôi',
    route: 'about-us',
    subMenus: [],
    pathActive: ['about-us'],
  },
  {
    label: 'Chính Sách & Thủ Tục',
    route: 'policy-and-procedures.Policies',
    subMenus: [],
    pathActive: ['policy-and-procedures'],
  },
  {
    label: 'Tin Tức',
    route: 'news.list',
    subMenus: [],
    pathActive: ['news'],
  },
  {
    label: 'Diễn Đàn',
    route: 'forum.list',
    subMenus: [],
    pathActive: ['forums'],
  },
  {
    label: 'Hỏi Đáp',
    route: null,
    pathActive: ['qa-library', 'question', 'experts'],
    subMenus: [
      {
        label: 'Hỏi đáp bộ ngành',
        route: 'question.list',
        subMenus: [],
        pathActive: ['question'],
      },
      {
        label: 'Hỏi đáp chuyên gia',
        route: 'experts.list',
        pathActive: ['experts'],
        subMenus: [],
      },
      {
        label: 'Thư viện hỏi đáp',
        route: 'qa-library.list',
        pathActive: ['qa-library'],
        subMenus: [],
      },
    ],
  },
  {
    label: 'Ấn phẩm & Dữ liệu',
    route: 'publication-documents.index',
    pathActive: ['publication-document'],
    subMenus: [],
  },
])

onBeforeMount(() => {
  let _primaryMenusArray = JSON.parse(JSON.stringify(primaryMenus.value))
  let _menuFunc = (menu) => {
    const menus = { ...menu }
    delete menus.subMenus

    if (!menu.subMenus || !menu.subMenus.length) {
      return menus
    }

    return [menus, window._.flatMapDeep(menu.subMenus, _menuFunc)]
  }
  window._.flatMapDeep(_primaryMenusArray, _menuFunc).map((item) => {
    if (typeof window.route !== 'undefined' && item.route) {
      if (window.route(item.route) === window.location.href) {
        defaultActive.value = item.route
      } else {
        const pathname = window.location.pathname.split('/')
        const currentMenu = _primaryMenusArray.find((item) => item.pathActive?.includes(pathname[1]))
        if (currentMenu) {
          defaultActive.value = currentMenu?.route ?? defaultActive.value
          if (currentMenu?.subMenus?.length) {
            const currentSubMenu = currentMenu.subMenus.find((item) => item.pathActive?.includes(pathname[1]))

            defaultActive.value = currentSubMenu?.route ?? defaultActive.value
          }
        } else {
          defaultActive.value = ''
          const pathNotMenuItem = window.location.href.split('/')
          if (pathNotMenuItem[3] == 'profile') {
            activeMyprofile.value = true
          }
        }
      }

      return window.route(item.route) === window.location.href
    }
  })
})

function onMenuClick(menu) {
  if (typeof window.route !== 'undefined' && menu.route) {
    defaultActive.value = menu.route
  }
}

function toggleLang() {
  let _input = currentLang.value
  loadLanguageAsync(_input.value)
  localStorage.setItem('cLocale', _input.value)
}

function toggleSidebar() {
  isSidebarToggled.value = !isSidebarToggled.value
}
</script>
<script>
import NotificationPopup from '@/Components/Notification/Index.vue'
import axios from '@/plugins/axios'
export default {
  components: { NotificationPopup },
  data() {
    return {
      notifications: this.$page?.props?.notifications ?? 0,
      isFocusToMyProfile: false,
    }
  },
  computed: {
    user() {
      return this.$page?.props?.auth?.user ?? ''
    },
  },
  methods: {
    async doLogout() {
      this.axios
        .delete(this.route('user.logout'))
        .then(() => {
          const guest_session = localStorage.getItem('guest-session')
          if (guest_session) {
            localStorage.removeItem('guest-session')
            localStorage.removeItem('guest-id')
            localStorage.removeItem('flag')
          }
          this.$inertia.visit(this.route('home'), { replace: true })
        })
        .catch((error) => {
          this.$message({ message: error?.message, type: 'error' })
        })
    },
    showProfile() {
      // eslint-disable-next-line no-undef
      this.$inertia.visit(route('user.profile'))
    },
    redirectHome() {
      this.$inertia.visit(this.route('home'))
    },
    toggleNotifications() {
      this.$refs.notificationPopup.toggle()
    },
    handleUpdateUnread(value) {
      this.notifications = value
    },
  },
}
</script>
<style scoped>
.primary-sidebar .sidebar-language-switcher select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 16l-6-6h12z'/%3E%3C/svg%3E");
}

.primary-sidebar .sidebar-language-switcher select:focus {
  box-shadow: unset;
}

.primary-sidebar .sidebar-menu .el-menu {
  border: unset;
}

.primary-sidebar .el-menu-item.is-active {
  background-color: var(--el-menu-active-bg-color);
  font-weight: bold;
}

.primary-sidebar .el-menu-item.is-active a {
  color: white;
}

.primary-sidebar .el-menu:not(.el-menu--inline) > .el-sub-menu,
.primary-sidebar .el-menu:not(.el-menu--inline) > .el-menu-item {
  border-bottom: 1px solid #eaeaea;
}
</style>
